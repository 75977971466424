
import { arrayOf, oneOf, shape } from 'vue-types';
import { isNullableString } from '@/types/isNullable';
import { visualList } from '~/types/visual';
import { customLinkArray } from '~/types/customLink';

const INSET_SIZE_DEFAULT = 'default';
const INSET_SIZE_LARGE = 'large';

export default {
  inheritAttrs: false,
  props: {
    visual: visualList,
    inset: arrayOf(
      shape({
        heading: isNullableString(),
        body: isNullableString(),
        alignment: oneOf(['left', 'center', 'right']),
        link: customLinkArray,
      }).loose,
    ),
  },
  computed: {
    insetProps() {
      return this.$first(this.inset);
    },
    insetLink() {
      return this.$first(this.insetProps?.link);
    },
    insetSize() {
      if (this.insetCentered) {
        return INSET_SIZE_LARGE;
      }

      return INSET_SIZE_DEFAULT;
    },
    insetCentered() {
      return this.insetProps?.alignment === 'center';
    },
    classes() {
      // on mobile, we want to push the image down so the overlay 'sticks out' at the top
      if (this.insetProps) {
        return ['pt-fluid-2l-3xl', 'm:pt-0'];
      }

      return null;
    },
    overlayClasses() {
      if (this.insetProps) {
        return [`align-${this.insetProps.alignment}`];
      }

      return null;
    },
  },
};
