
import { customLink } from '~/types/customLink';
export default {
  props: {
    ...customLink,
  },
  computed: {
    component() {
      if (this.type === 'internal') {
        return 'nuxt-link';
      } else if (this.type === 'external') {
        return 'a';
      } else {
        return 'button';
      }
    },
    attributes() {
      if (this.type === 'internal') {
        const { url } = this.$first(this.locationEntry) || {};
        return {
          to: this.$pathFromUrl(url) || '/',
        };
      } else {
        return {
          target: '_blank',
          rel: 'noopener',
          href: this.locationUrl,
        };
      }
    },
  },
};
